<template>
  <div class="outContainer">
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
      <span>{{ tableData[0] ? tableData[0].sampleTypeName : '--' }}</span>
    </div>
    <div class="search">
      <el-button size="mini" @click="init" type="primary" icon="el-icon-refresh"
        >刷新</el-button
      >
      <el-button size="mini" @click="handleAdd(null)">新增</el-button>
    </div>
    <div class="tableContainer">
      <el-table height="100%" :data="tableData">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
              v-if="scope.row.pipelineTemplateId == 20000"
              border
              :header-cell-style="{ background: '#ddd' }"
              :data="scope.row.standardsLevels"
            >
              <el-table-column
                align="center"
                label="等级"
                prop="level"
              ></el-table-column>
              <el-table-column align="center" label="核辐射值" prop="rgb">
                <template slot-scope="props">
                  <!-- <span
                    class="levelColor"
                    :style="{ backgroundColor: `rgb(${props.row.rgb})` }"
                  ></span> -->
                  <!-- <p>{{ props.row.rgb }}</p> -->
                  <p>{{ props.row.NuclearRadiationValue }}</p>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="描述"
                prop="remark"
              ></el-table-column>
            </el-table>
            <el-table
              v-else
              border
              :header-cell-style="{ background: '#ddd' }"
              :data="scope.row.standardsLevels"
            >
              <el-table-column
                align="center"
                label="等级"
                prop="level"
              ></el-table-column>
              <el-table-column align="center" label="颜色" prop="rgb">
                <template slot-scope="props">
                  <span
                    class="levelColor"
                    :style="{ backgroundColor: `rgb(${props.row.rgb})` }"
                  ></span>
                  <p>{{ props.row.rgb }}</p>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="简述"
                prop="title"
              ></el-table-column>
              <el-table-column
                align="center"
                label="描述"
                prop="remark"
              ></el-table-column>
            </el-table>
          </template>
          <!-- <template v-else slot-scope="scope"> </template> -->
          <!-- <template slot-scope="scope">
            {{ scope.row }}
          </template> -->
        </el-table-column>
        <el-table-column
          align="center"
          label="流水线模板名称"
          prop="pipelineTemplateName"
        ></el-table-column>
        <el-table-column
          align="center"
          label="样品最小重量（克）"
          prop="sampleMinWeight"
        ></el-table-column>
        <el-table-column
          align="center"
          label="样品最大重量（克）"
          prop="sampleMaxWeight"
        ></el-table-column>
        <el-table-column
          align="center"
          label="创建时间"
          prop="createTimeStr"
        ></el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleAdd(scope.row, 'edit')"
              >修改</el-button
            >
            <el-button size="mini" @click="handleDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-bind:child-msg="searchInfo"
        @callFather="callFather"
      ></pagination>
    </div>
    <!-- 新增检测项及其标准弹框 -->
    <el-dialog
      :visible.sync="testItemDialog"
      title="检测项及其标准"
      :modal="false"
      width="70%"
    >
      <el-form
        ref="testItemForm"
        :model="testItemForm"
        label-position="right"
        status-icon
        label-width="180px"
        :rules="testItemRules"
        style="width: 95%; overflow: auto"
      >
        <el-form-item label="检测项" prop="pipelineTemplateId">
          <el-select
            v-model="testItemForm.pipelineTemplateId"
            placeholder="请选择"
            style="width: 100%"
            @change="handlePipelineTemplateIdChange"
          >
            <el-option
              v-for="item in pipelineList"
              :key="item.id"
              :label="
                item.name + '-' + (item.fee ? item.fee + '元/次' : '暂无定价')
              "
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <!-- 检测项名称 -->
              <!-- <span style="float: right; color: #8492a6; font-size: 13px">{{ item.fee ? item.fee + '元/次' : "暂无定价" }}</span> 费用 -->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="testItemForm.pipelineTemplateId != 20000"
          label="样品克重范围（克）"
          prop="sampleMinWeight"
        >
          <div class="weightRange">
            <el-input
              placeholder="请输入最小克重"
              type="number"
              v-model="testItemForm.sampleMinWeight"
              @input="handleInput('sampleMinWeight')"
            ></el-input>
            <el-col class="line" :span="2">~</el-col>
            <el-input
              placeholder="请输入最大克重"
              type="number"
              v-model="testItemForm.sampleMaxWeight"
              @input="handleInput('sampleMaxWeight')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          v-if="testItemForm.pipelineTemplateId == 20000"
          label="核辐射值标准等级"
          prop="standardsLevels"
        >
          <el-button
            class="button-new-tag"
            size="small"
            @click="addStandard('small')"
            style="width: 100%; border: dashed #c0c4cc 1px"
            >- 往小添加</el-button
          >
          <div
            class="colorStandard"
            v-for="(item, index) in testItemForm.standardsLevels"
            :key="index"
          >
            <el-form
              ref="testItemRef"
              :model="item"
              label-width="90px"
              style="
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                align-items: center;
              "
            >
              <p class="levelName">
                等级{{ item.level }}（小于0为不合格，大于等于0合格）
              </p>
              <el-form-item
                label="核辐射值："
                style="
                  width: 100%;
                  display: flex;
                  margin: 5px 0;
                  align-items: center;
                  margin-bottom: 20px;
                "
              >
                <el-input
                  placeholder="请输入核辐射值"
                  type="number"
                  v-model="item.NuclearRadiationValue"
                  style="flex: 1"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="简述："
                prop="title"
                style="width: 100%; margin-bottom: 20px"
              >
                <el-input
                  placeholder="简述"
                  type="text"
                  v-model="item.title"
                ></el-input>
              </el-form-item>
              <el-form-item label="描述：" style="width: 100%">
                <el-input
                  placeholder="描述"
                  type="text"
                  v-model="item.remark"
                ></el-input>
              </el-form-item>
            </el-form>
            <i class="el-icon-delete" @click="deleteStandard()"></i>
          </div>
          <el-button
            class="button-new-tag"
            size="small"
            @click="addStandard('big')"
            style="width: 100%; border: dashed #c0c4cc 1px"
            >+ 往大添加</el-button
          >
        </el-form-item>

        <el-form-item v-else label="颜色标准等级" prop="standardsLevels">
          <el-button
            class="button-new-tag"
            size="small"
            @click="addStandard('small')"
            style="width: 100%; border: dashed #c0c4cc 1px"
            >- 往小添加</el-button
          >
          <div
            class="colorStandard"
            v-for="(item, index) in testItemForm.standardsLevels"
            :key="index"
          >
            <el-form
              ref="testItemRef"
              :rules="testItemRules"
              :model="item"
              label-width="90px"
              style="
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                align-items: center;
              "
            >
              <p class="levelName">
                等级{{ item.level }}（小于0为不合格，大于等于0合格）
              </p>
              <el-form-item
                label="颜色："
                style="
                  width: 100%;
                  display: flex;
                  margin: 5px 0;
                  align-items: center;
                  margin-bottom: 20px;
                "
              >
                <el-input
                  placeholder="R（0~255）"
                  type="number"
                  v-model="item.R"
                  style="flex: 1"
                ></el-input>
                <el-input
                  placeholder="G（0~255）"
                  type="number"
                  v-model="item.G"
                  style="flex: 1"
                ></el-input>
                <el-input
                  placeholder="B（0~255）"
                  type="number"
                  v-model="item.B"
                  style="flex: 1"
                ></el-input>
                <span
                  class="levelColor"
                  :style="{
                    backgroundColor: `rgb(${item.R ? item.R : 0},${
                      item.G ? item.G : 0
                    },${item.B ? item.B : 0})`,
                  }"
                ></span>
              </el-form-item>
              <el-form-item
                label="简述："
                prop="title"
                style="width: 100%; margin-bottom: 20px"
              >
                <el-input
                  placeholder="简述"
                  type="text"
                  v-model="item.title"
                ></el-input>
              </el-form-item>
              <el-form-item label="描述：" style="width: 100%; margin-top: 5px">
                <el-input
                  placeholder="描述"
                  type="text"
                  v-model="item.remark"
                ></el-input>
              </el-form-item>
            </el-form>
            <i class="el-icon-delete" @click="deleteStandard(index)"></i>
          </div>
          <el-button
            class="button-new-tag"
            size="small"
            @click="addStandard('big')"
            style="width: 100%; border: dashed #c0c4cc 1px"
            >+ 往大添加</el-button
          >
        </el-form-item>
        <!-- <el-form-item label="合格等级">
          <el-radio-group v-model="testItemForm.qualifiedLevel">
            <el-radio
              v-for="(item, index) in testItemForm.standardsLevels"
              :key="index"
              :label="index"
              >等级{{ index + 1 }}</el-radio
            >
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="testItemDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddItem('testItemForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/Pagination.vue'
import {
  deleteStandards,
  getStandards,
  getPipelineTemplateList,
  addStandards,
  updateStandards,
} from '@/api/public.js'
import { timeToTime } from '@/api/publicFun.js'
export default {
  data() {
    return {
      searchInfo: {
        pageNo: 1,
        pageSize: 10,
        param: {
          sampleTypeId: null,
        },
      },
      tableData: [],
      sampleTypeId: null,
      testItemDialog: false,
      testItemForm: {
        standardsLevels: [
          {
            level: -1,
          },
          {
            level: 0,
          },
        ],
      }, // 新增检测项表单
      testItemRules: {
        // 新增检测项表单验证
        test: [{ required: true, message: '请选择检测项', trigger: 'change' }],

        title: [
          { required: true, message: '请输入样品标准克重', trigger: 'blur' },
          {
            type: 'string',
            max: 10,
            message: '字数不能超过10个字符',
            trigger: 'blur',
          },
        ],

        weight: [
          { required: true, message: '请输入样品标准克重', trigger: 'blur' },
        ],
        standardColor: [
          { required: true, message: '请输入合格颜色', trigger: 'blur' },
        ],
        unStandardColor: [
          { required: true, message: '请输入不合格颜色', trigger: 'blur' },
        ],
        fee: [{ required: true, message: '请输入检测费用', trigger: 'blur' }],
      },
      pipelineList: [], // 检测流水线列表
      pipelineDefaultValueList: [
        {
          name: '农药残留检测',
          sampleMinWeight: '0',
          sampleMaxWeight: '100',
          standardsLevels: [
            {
              level: -1,
              R: '193',
              G: '255',
              B: '193',
              title: '阳性结果',
              remark:
                '样品溶液呈无色或淡蓝色，样品中有机磷和氨基甲酸酯类农药超标。',
              rgb: '193,255,193',
            },
            {
              level: 0,
              R: '255',
              G: '255',
              B: '0',
              title: '阴性结果',
              remark: '样品溶液呈黄色，样品中有机磷和氨基甲酸酯类农药未超标。',
              rgb: '255,255,0',
            },
          ],
        },
        {
          name: '二氧化硫检测',
          sampleMinWeight: '0',
          sampleMaxWeight: '100',
          standardsLevels: [
            {
              level: -2,
              R: '255',
              G: '255',
              B: '0',
              title: '阳性结果',

              remark:
                '样品溶液呈黄色，样品中二氧化硫含量大于500mg/kg,超出国家标准范围。',
              rgb: '255,255,0',
            },
            {
              level: -1,
              R: '173',
              G: '230',
              B: '47',
              title: '阳性结果',
              remark: '样品溶液呈黄绿色，样品中二氧化硫含量在200mg/kg左右。',
              rgb: '173,230,47',
            },
            {
              level: 0,
              R: '238',
              G: '230',
              B: '133',
              title: '阴性结果',
              remark: '样品溶液呈淡黄色，样品中二氧化硫含量在25mg/kg左右。',
              rgb: '238,230,133',
            },
            {
              level: 1,
              R: '255',
              G: '255',
              B: '255',
              title: '阴性结果',
              remark: '样品溶液呈无色，样品中不含二氧化硫。',
              rgb: '238,230,133',
            },
          ],
        },
        {
          name: '甲醛检测',
          sampleMinWeight: '5',
          sampleMaxWeight: '15',

          standardsLevels: [
            {
              level: -1,
              R: '255',
              G: '69',
              B: '0',
              title: '阳性结果',
              remark: '阳性结果，样品溶液呈橙红色，样品中含有甲醛。',
              rgb: '255,69,0',
            },
            {
              level: 0,
              R: '255',
              G: '255',
              B: '255',
              title: '阴性结果',
              remark: '阴性结果，样品溶液呈无色，样品中不含甲醛。',
              rgb: '255,255,255',
            },
          ],
        },
        {
          name: '重金属镉检测',
          sampleMinWeight: '0',
          sampleMaxWeight: '100',
          qualifiedLevel: 0,
          standardsLevels: [
            {
              level: -1,
              R: '238',
              G: '118',
              B: '33',
              title: '阳性结果',
              remark: '阳性结果，样品溶液呈土黄色，样品中重金属镉超标。',
              rgb: '238,118,33',
            },
            {
              level: 0,
              R: '199',
              G: '21',
              B: '133',
              title: '阴性结果',
              remark: '阴性结果，样品溶液呈紫红色，样品中重金属镉未超标。',
              rgb: '199,21,133',
            },
          ],
        },
        {
          name: '重金属铅检测',
          sampleMinWeight: '0',
          sampleMaxWeight: '100',
          qualifiedLevel: 0,
          standardsLevels: [
            {
              level: -1,
              R: '255',
              G: '106',
              B: '106',
              title: '阳性结果',
              remark:
                '阳性结果，样本溶液呈橙红色、粉红色，样品中重金属铅超标。',
              rgb: '255,106,106',
            },
            {
              level: 0,
              R: '255',
              G: '215',
              B: '0',
              title: '阴性结果',
              remark: '阴性结果，样本溶液呈黄色，样品中重金属铅未超标。',
              rgb: '255,215,0',
            },
          ],
        },
        {
          name: '吊白块检测',
          sampleMinWeight: '0',
          sampleMaxWeight: '100',
          qualifiedLevel: 0,
          standardsLevels: [
            {
              level: -1,
              R: '199',
              G: '21',
              B: '133',
              title: '阳性结果',
              remark: '阳性结果，样品溶液呈紫色，样品中含有吊白块。',
              rgb: '199,21,133',
            },
            {
              level: 0,
              R: '255',
              G: '255',
              B: '255',
              title: '阴性结果',
              remark: '阴性结果，样品溶液呈无色或其他颜色，样品中不含吊白块。',
              rgb: '255,255,255',
            },
          ],
        },
        // {
        //   name: '核辐射',
        //   sampleMinWeight: '0',
        //   sampleMaxWeight: '100',
        //   qualifiedLevel: 0,
        //   standardsLevels: [
        //     {
        //       level: -1,
        //       R: '199',
        //       G: '21',
        //       B: '133',
        //       remark: '阳性结果，样品溶液呈紫色，样品中含有吊白块。',
        //       rgb: '199,21,133',
        //     },
        //     {
        //       level: 0,
        //       R: '255',
        //       G: '255',
        //       B: '255',
        //       remark: '阴性结果，样品溶液呈无色或其他颜色，样品中不含吊白块。',
        //       rgb: '255,255,255',
        //     },
        //   ],
        // },
      ], // 检测流水线默认值列表
      row: null,
      isAddTag: false,
    }
  },

  components: {
    pagination,
  },

  computed: {},

  mounted() {
    this.searchInfo.param.sampleTypeId = this.$route.query.sampleTypeId
    this.init()
  },

  methods: {
    async init() {
      await getStandards(this.searchInfo).then(res => {
        this.tableData = res.success ? res.data.list : []
        this.tableData.forEach(element => {
          element.createTimeStr = timeToTime(element.createTime, 'seconds')
        })
      })
    },
    handleInput(value) {
      this.testItemForm[value] = this.testItemForm[value]
        ? Number(this.testItemForm[value])
        : ''
    },
    // 动态加载颜色
    changeBackgroundColorBasedOnContent(element) {
      var value = element.textContent.trim().toLowerCase()
      // 根据内容的长度或其他逻辑来决定颜色，这里只是示例
      var color = '#' + Math.floor(Math.random() * 16777215).toString(16)
      element.style.backgroundColor = color
    },

    // 删除
    handleDel(row) {
      this.$confirm('确认删除吗？').then(() => {
        deleteStandards({ param: row.id }).then(res => {
          this.$message({
            message: res.success ? '删除成功' : res.msg,
            type: res.success ? 'success' : 'fail',
          })
          this.init()
        })
      })
    },
    // 获取检测流水列表
    async getPipeline() {
      await getPipelineTemplateList().then(res => {
        this.pipelineList = res.success ? res.data : []
      })
    },
    // 打开新增编辑弹框
    async handleAdd(row) {
      // 处理模板选择默认值
      this.isAddTag = false
      if (!row) {
        this.testItemForm = {
          standardsLevels: [
            {
              level: -1,
            },
            {
              level: 0,
            },
          ],
        }
        this.isAddTag = true
      }
      console.log(row, 'row')
      this.row = row
      this.getPipeline()
      if (row) {
        this.testItemForm = { ...row }
        this.testItemForm.standardsLevels.forEach(element => {
          console.log(element, 'element')
          if (row.pipelineTemplateId != 20000) {
            let arr = element.rgb.split(',')
            this.$set(element, 'R', Number(arr[0]))
            this.$set(element, 'G', Number(arr[1]))
            this.$set(element, 'B', Number(arr[2]))
          }
          // this.$set(element, 'NuclearRadiationValue', Number(arr[2]))
          element = { ...element }
        })
        console.log(this.testItemForm, 999)
      }
      this.testItemForm.sampleTypeId = this.searchInfo.param.sampleTypeId
      this.testItemDialog = true
    },
    handlePipelineTemplateIdChange(e) {
      // return
      console.log(e, 'handlePipelineTemplateIdChange e')
      if (this.isAddTag) {
        console.log('是新增模式')
        console.log(this.pipelineList, 'pipelineList')
        let pipeline = this.pipelineList.find(pipeline => {
          return pipeline.id == e
        })
        let pipelineDefaultValue = this.pipelineDefaultValueList.find(item => {
          return item.name == pipeline.name
        })
        if (pipelineDefaultValue) {
          this.testItemForm = { ...this.testItemForm, ...pipelineDefaultValue }
          console.log(this.testItemForm, 'testItemForm')
        }
      }
    },
    // 新增颜色等级
    addStandard(type) {
      if (type === 'small') {
        this.testItemForm.standardsLevels.unshift({
          level: this.testItemForm.standardsLevels[0].level - 1,
        })
      }
      if (type === 'big') {
        this.testItemForm.standardsLevels.push({
          level:
            this.testItemForm.standardsLevels[
              this.testItemForm.standardsLevels.length - 1
            ].level + 1,
        })
      }
    },
    // 删除颜色等级
    deleteStandard(index) {
      this.testItemForm.standardsLevels.splice(index, 1)
    },
    // 确认新增/修改检测标准
    confirmAddItem() {
      console.log(this.testItemForm, 'testItemForm')
      // console.log(this.pipelineList, 'pipelineList')
      console.log(this.row, 'row')

      console.log(this.$refs, 'refs')

      let flag = false
      this.$refs['testItemRef'].forEach(item => {
        item.validate(valid => {
          if (valid) {
            flag = false
          } else {
            flag = true
          }
        })
      })
      // console.log(flag, 'flag')
      // return
      if (!flag) {
        this.$confirm('确认执行该操作吗？').then(async () => {
          if (this.testItemForm.pipelineTemplateId != 20000) {
            this.testItemForm.standardsLevels.forEach(element => {
              element.rgb = `${element.R},${element.G},${element.B}`
              element.level = Number(element.level)
            })
          }
          console.log(this.testItemForm, 'testItemForm2')
          // return
          if (!this.row) {
            await addStandards({ param: this.testItemForm }).then(res => {
              this.$message({
                message: res.success ? '提交成功' : res.msg,
                type: res.suucess ? 'success' : 'fail',
              })
              this.init()
              this.testItemDialog = false
            })
          } else {
            await updateStandards({ param: this.testItemForm }).then(res => {
              this.$message({
                message: res.success ? '提交成功' : res.msg,
                type: res.suucess ? 'success' : 'fail',
              })
              this.init()
              this.testItemDialog = res.success ? false : this.testItemDialog
            })
          }
        })
      }
    },

    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage
      this.init()
    },
    // 返回上一页
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style scoped lang="scss">
.levelName {
  text-align: center;
  width: 100%;
  color: rgb(93, 93, 240);
}
/deep/ .el-dialog {
  max-height: 80%;
  overflow-y: auto;
}
.levelColor {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: solid 1px #000;
}

.weightRange {
  display: flex;
  justify-content: space-around;
}
.colorStandard {
  display: flex;
  width: 100%;
  align-items: center;
  /deep/ .el-form-item__content {
    margin-left: 0 !important;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .el-form-item .el-form-item {
    flex: 1;
    display: flex;
    align-items: center;
    width: 50%;
    .el-form-item__label {
      width: 65px !important;
    }
  }
  .el-icon-delete {
    margin-left: 5px;
    color: red;
    cursor: pointer;
    font-size: 18px;
  }
}
</style>
